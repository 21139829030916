<template>
  <div v-if="!active">
    <v-jsoneditor v-model="computedSql.query" :plus="false" :options="options" />
    <v-select v-model="computedSql.realtime" :items="['also', 'only']" class="my-4" :disabled="readOnly" label="realtime" hide-details outlined />
    <v-switch v-model="computedSql.first_value" :disabled="readOnly" value hide-details label="Primeiro valor" />
    <v-switch v-model="computedSql.atemporal" :disabled="readOnly" value hide-details label="Atemporal" />

    <div v-if="computedSql.atemporal === false" class="mt-6">
      <v-text-field v-model="computedSql.date_field" :disabled="readOnly" label="date_field" outlined />
      <v-text-field v-model="computedSql.date_filter_format" :disabled="readOnly" label="date_filter_format" outlined />
      <v-text-field v-model="computedSql.local_date_parser" :disabled="readOnly" label="local_date_parser" hide-details outlined />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sql: Object,
    show: Boolean,
    editable: Boolean
  },
  computed: {
    computedSql: {
      get() {
        return this.sql
      },
      set(sql) {
        this.$emit('update:sql', sql)
      }
    },
    active: {
      get() {
        return this.show
      }
    },
    readOnly() {
      if (this.editable === undefined) return false
      return this.editable
    },
    options: {
      get() {
        return { mode: !this.readOnly ? 'code' : 'view', mainMenuBar: false }
      }
    }
  }
}
</script>
